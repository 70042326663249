<template>
    <v-card>
        <v-toolbar dense color="primary" dark class="mb-4">
            <v-toolbar-title class="text-subtitle-1">{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('close')">
                <v-icon>mdi-close-box</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                    dense
                    placeholder="Enter Phone Number"
                    full-width
                    solo
                    v-model="phoneNumberData"
                    label="Phone Number"
                    clearable
                    required
                    :rules="[
                    v => !!v || 'Phone Number is required',
                    v => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(v) || 'Phone number must be valid'
                    
                    ]"
                ></v-text-field>

                <v-row v-if="contact && !reply">
                    <v-col cols="12" lg="4">
                        <v-select
                            :loading="loading"
                            :items="contactTypes"
                            dense
                            solo
                            item-text="type"
                            item-value="type"
                            placeholder="Select contact type"
                            v-model="contactType"
                            @change="selectContactType"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <v-select
                            :items="templateNames"
                            dense
                            solo
                            placeholder="Select template name"
                            @change="selectTemplate($event)"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row v-if="!contact">
                    <v-col cols="12">
                        <v-select
                            label="Search ID"
                            v-model="searchID"
                            placeholder="Select Search ID"
                            :loading="loading"
                            :items="searches"
                            solo
                            item-text="description"
                            item-value="id"
                            required
                            :rules="[
                                v => !!v || 'Search ID is required'
                             ]"
                            @change="validateSend"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-textarea
                    ref="textMessage"
                    solo
                    v-model="messageData"
                    placeholder="Enter text message"
                    label="Text Message"
                    clearable
                    required
                    :rules="[v => !!v || 'Message is required']"
                ></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="primary"
                :disabled="sendTextMessageDisabled"
                @click="sendTextMessage"
                :loading="loading"
            >
                <v-icon class="mr-2">mdi-message-text</v-icon>Send Text
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "send-text-message",
    components: {},
    props: {
        reply: {
            type: Boolean,
            required: false,
            default: false,
        },
        search_id: {
            type: [String, Number],
            required: false,
            default: "",
        },
        order: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
        contact: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
        phoneNumber: {
            required: false,
        },
        title: {
            type: String,
            required: false,
            defaut: "",
        },
    },
    data() {
        return {
            valid: true,
            searchID: this.search_id,
            phoneNumberData: this.phoneNumber,
            messageData: "",
            contactType:
                this.contact === null || Object.keys(this.contact).length === 0
                    ? ""
                    : this.$_.upperFirst(this.contact.type.split(" ")[0]),
            contactTypes: [],
            templateNames: [],
            sendTextMessageDisabled: true,
        };
    },
    computed: {
        ...mapGetters("Template", ["templates", "loading"]),
        ...mapGetters("Order", ["loading", "searches"]),
        ...mapGetters("Message", ["loading"]),
    },
    watch: {
        phoneNumberData() {
            this.validateSend();
        },
        messageData() {
            this.validateSend();
        },
        templates() {
            this.contactTypes = Object.keys(this.templates);
            this.templateNames =
                this.contactType &&
                this.$_.has(this.templates, this.contactType)
                    ? Object.keys(this.templates[this.contactType])
                    : [];
        },
    },
    mounted() {
        this.getTemplates();
        this.getSearches();
    },
    methods: {
        ...mapActions("Template", ["getTemplates"]),
        ...mapActions("Message", ["sendMessage", "createContact"]),
        ...mapActions("Order", ["getSearches"]),
        validateSend() {
            this.sendTextMessageDisabled =
                this.phoneNumberData == null ||
                this.phoneNumberData.length == 0 ||
                this.messageData == null ||
                (this.searchID === "" &&
                    Object.keys(this.order).length === 0) ||
                this.messageData.length == 0;
        },
        setMessage(template) {
            this.messageData = this.merge(template.body);
        },
        selectContactType(contactType) {
            this.templateNames = Object.keys(this.templates[contactType]);
        },
        selectTemplate(name) {
            this.setMessage(this.templates[this.contactType][name]);
        },
        async sendTextMessage() {
            // TODO: REFACTOR TO NOT PULL FROM TWILIO NUMBER FROM ENV VARS
            const messageData = {
                To: this.phoneNumberData.replace(/\D+/g, ""),
                Body: this.messageData,
                From: process.env.VUE_APP_TWILIO_NUMBER,
                search_id:
                    "SearchId" in this.order
                        ? this.order.SearchId
                        : this.searchID.toString(),
            };
            if (this.contact) {
                // take the phone number from the input field if overriding
                this.contact.phone = this.phoneNumberData.replace(/\D+/g, "");
                const result = await this.createContact(this.contact);
                if (Object.keys(result).length === 0) {
                    this.$toast({
                        content: "Error in creating contact",
                        color: "error",
                    });
                    this.$emit("close");
                    return;
                }
            }

            const result = await this.sendMessage(messageData);
            if ("id" in result) {
                this.$toast({
                    content: `Message Sent to ${this.phoneNumberData}`,
                });
            }
            this.$emit("sent", result);
            this.$emit("close");
        },
        merge(text) {
            // TODO: Use Regex and make this more robust
            let textMessage = text;
            ["applicant", "search_id", "property"].forEach((key) => {
                switch (key) {
                    case "applicant":
                        textMessage = textMessage.replace(
                            `{${key}}`,
                            this.order.Applicant.name
                        );
                        break;
                    case "property":
                        textMessage = textMessage.replace(
                            `{${key}}`,
                            this.order.Property.name
                        );
                        break;
                    case "search_id":
                        textMessage = textMessage.replace(
                            `{${key}}`,
                            this.order.SearchId
                        );
                        break;
                }
            });
            return textMessage;
        },
    },
};
</script>

<style scoped>
</style>